<template>
    <div class="main-content" style="
      padding-bottom: 10vh;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center !important;
      ">
        <div class="dashboard-banner position-relative">
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title">{{ $t("redeemable_etnex") }}</h1>
                <div></div>
                <!-- <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i> -->
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>
            <div class="px-2">
              <b-form @submit.prevent="doWithdraw"> 
                <div class="sell-etnex-container black-dark-blue-card mb-3">
                  <div class="sell-card">
                    <div class="">
                      <div class="mb-1 text-12 text-white">
                        {{ $t("wallet_address") + " (BEP20)" }}
                      </div>
                      <div class="custom-input-group black-dark-blue-input mb-3">
                        <input class="form-control text-white" v-model="member.username" required disabled/>
                      </div>
                    </div>
                    <div class="">
                      <div class="mb-1 text-12 text-white">
                          {{ $t("total_etnex_redeemable") }}
                      </div>
                      <div class="custom-input-group black-dark-blue-input mb-3">
                        <input class="form-control text-white" :value="parseFloat(member.point4).toString()" readonly disabled/>
                      </div>
                    </div>
                    <div class="">
                      <div class="mb-1 text-12 text-white">
                        {{ $t("redeemAmount")}}
                      </div>
                      <div class="custom-input-group black-dark-blue-input mb-3">
                        <input 
                          type="number" 
                          class="custom-input form-control text-white" 
                          :placeholder="$t('enter_etnex_remark')"
                          v-model="amount" 
                         
                        />
                      </div>
                    </div>
                    <!-- <div class="mb-2 text-12 text-white">
                      {{ $t("available_etnex_convert") }} {{parseFloat(member.etnex_limit).toFixed(2) }} ETNEX
                    </div> -->
                    <div class="">
                      <div class="mb-1 text-12 text-white">
                        {{ $t("sec_password") }}
                      </div>
                      <div class="row">
                        <div class="input-group col-ms-12 ml-1">
                          <input v-for="(digit, index) in digits" :key="index" type="password"
                            class="form-control label-input "
                            @input="updateDigit(index, $event.target.value)" min="0" max="9" pattern="[0-9]"
                            required maxlength="1" ref="inputFields1" v-model="digits[index]" />
                          <!-- <input 
                            type="password" 
                            class="custom-input form-control text-white" 
                            v-model="sec_pwd" 
                          /> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="mt-3 mb-4 btn-curved-wallet"
                  variant="primary"
                  block
                  :disabled="isLoading"
                  >{{ isLoading ? $t("loading...") : $t("redeem") }}</button>
              </b-form>
           

              <h2 class="text_h2_etnex text text-light-green mt-4 pt-10">{{ $t("redeem_history") }}</h2>
              <div class="scrollable-container">
                <div v-if="dataList.length > 0">
                  <div class="notification-card" v-for="item in dataList" :key="item.id">
                    <div class="icon">
                        <img src="../../../assets/images/etnex_wallet/withdraw_icon.png" alt="icon" class="icon">
                    </div>
                    <div class="content">
                      <span class="title text">{{ $t("withdraw") }} {{ $t(site[item.status]) }}</span>
                      <span v-if="item.status == 2">
                        <span class="font-weight-bold text-10">TXID: {{ item.txid }}</span>
                      </span>
                    </div>
                    <div class="text-right">
                      <span class="con_address text">{{ getDate(item.created_at) }}</span>
                      <span class="text-grey"> {{ " | "}}</span>
                      <span class="con_address text">{{ getTime(item.created_at) }}</span>
                      <div class="text-light-green font-weight-bold">{{ item.amount }} ETNEX</div>                
                    </div>
                  </div>
                </div>
                <h3 v-else class="no-data-text text">
                  {{ $t("no_data") }}
                </h3>
                <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                      <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                          {{ $t("load_more") }}
                      </button>
                  </div>
              </div>
            </div>
        </div>
        <Dialog ref="msg"></Dialog>
        <Success v-if="SuccessModal" @closePopup="SuccessModal = false" :message="successMessage"></Success>
    </div>
  </template>
  <script>
  import Dialog from "../../../components/dialog.vue";
  import Success from '../popup/profilesuccess.vue';
  import { getMemberInfo, getDownloadLink, getWithdrawRecord, redeem } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  export default {
  
    components: {
      Dialog,
      Success,
    },
    data() {
      return {
        address: "",
        digits: new Array(4).fill(''),
        site: ["pending", "approved", "success", "rejected"],
        amount: null,
        dataList: [],
        sec_pwd: "",
        SuccessModal: false,
        isLoading: false,
        point2: '',
        username: '',
        limit: 0,
        market_price: 0,
        currentPage: 1,
        lastPage: 1,
        transferRecord: [],
        moreRecords: false,
        member: {
        point1: 0,
        },
      };
    },
    methods: {
      updateDigit(index, value) {
        this.digits[index] = value;
        this.sec_pwd = this.digits.join('');
        if (index < this.digits.length - 1 && this.$refs.inputFields1[index + 1] ) {
            this.$refs.inputFields1[index + 1].focus();
        }
      },
      getDate(dateTime) {
        const date = new Date(dateTime);
        const datePart = date.toLocaleDateString('en-US');
        return datePart;
      },
      getTime(dateTime) {
        const date = new Date(dateTime);
        const timePart = date.toLocaleTimeString('en-US', { hour12: false });
        return timePart;
      },
      goDetails(item) {
        this.$router.push({
          name: "recordDetails",
          params: {
            item: item,
            type: 'withdraw'
          },
        });
      },
      getRecord() {
        var result = getWithdrawRecord('REDEEM', this.currentPage);
        var self = this;
        self.isLoading = true;

        result
          .then(function (value) {
            var dataList = value.data.data.data;
            self.currentPage += 1;
            self.lastPage = value.data.data.last_page;
            for (let i = 0; i < dataList.length; i++) {
              self.dataList.push(dataList[i]);
            }
            self.isLoading = false;
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },
      goBack() {
        this.$router.go(-1)
      },
      validateAmount() {
        if (this.amount !== null && this.amount >= 1000) {
          if (this.amount % 100 === 0) {
            return true;
          } else {
            this.$refs.msg.makeToast("danger", this.$t("increments100"));
            return false;
          }
        } else {
          this.$refs.msg.makeToast("danger", this.$t("min_1000etnex"));
          return false;
        }
      },
      doWithdraw() {
        if (!this.validateAmount()) {
          return;
        }
        console.log(this.preset_address);
        let formData = new FormData();
        formData.append("amount", this.amount);
        formData.append("wallet_type", "point4");
        formData.append("address", this.member.username);
        formData.append("sec_password", this.sec_pwd);
        console.log(formData);

        var self = this;
        self.isLoading = true;
        var result = redeem(formData);

        result
          .then(function (value) {
            if (value.data.code == 0) {
              self.successMessage = "withdrawal_success";
              self.SuccessModal = true;
              setTimeout(() => {
                self.$router.push("/web/mywallet");
              }, 4000);
            } else {
              self.$refs.msg.makeToast("danger", self.$t(value.data.message));
            }
            self.sec_pwd = "";
            self.isLoading = false;
            })
            .catch(function (error) {
              self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
              self.isLoading = false;
              self.sec_pwd = "";
            });
        },
      globalInfo() {
        var result = getDownloadLink();
        var self = this;
        result
          .then(function (value) {
            self.dfi_price = value.data.data.system.DFI_PRICE;
          })
          .catch(function () {});
      },
      memberInfo() {
        var result = getMemberInfo();
        var self = this;
        result
          .then(function (value) {
            self.member = value.data.data;
          })
          .catch(function () {});
      },
    },
    created() {
    this.globalInfo();
    this.memberInfo();
    this.getRecord();
    },
  };
  </script>
  
<style scoped>
  /* text css */
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  

  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

  .text_16{
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .weight_600{
    font-weight: 600;
  }
  .text_h2_etnex{
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  /* text css end */
  
  .sell-etnex-container {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    max-width: 100%;
    margin: auto;
    color: white;
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 8px;
  }
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }


  .sell-card {
  border-radius: 12px;
  color: #fff;
  padding: 8px;
  font-family: 'Poppins', sans-serif;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0px !important;
}

.logo {
  max-width: 25%;
  margin-right: 0.5rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}

.available {
  margin-bottom: 1rem;
}

.form-control {
  border: 2px solid #0BDDCC;
  background-color: transparent;
  color: #fff;
}

.text_m_a{
  color: var(--Secondary, #7E8088);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
 

.custom-input-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden; 
  background-color: transparent;
  color: #fff;
}

.custom-input {
  flex-grow: 1;
  border: none;
  padding: 10px;
  color: white;
  background: transparent;
}

.currency-label {
  padding: 10px;
  color: white;
  background: transparent;
}

.custom-input:focus {
  outline: none;
}
.sell-button {
    width: 100%;
    border-radius: 8px;
    background-image: url('../../../assets/images/etnex_home/colourful_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }

  .info-box {
    background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
    border-radius: 8px;
    color: white;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    font-family: Poppins;
    flex-direction: column;
    justify-content: space-between;
  }

  .info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0; 
  }

  .info-title{
    color: var(--Secondary, #7E8088);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
  }
  .notification-card {
      display: flex;
      align-items: center;
      background: linear-gradient(90deg, #152331 0%, #000000 100%);
      color: #fff;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 7px;
  }

  .icon {
      margin-right: 5px;
      flex-shrink: 0;
  }

  .icon img {
      background-color: transparent;
      border-radius: 5px;
      padding: 8px;
  }

  .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
  }

  .upper-row, .lower-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
  }

  .title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
  }

  .date {
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1px;
  }

  .stat-circle-container {
      flex: 0 0 auto;
  }

  circle.bg
  {
      fill: #C20651;
      stroke: #251740;
      stroke-width: 3;
  }

  text
  {
      font-size: 4px;
      text-anchor: middle;
      fill: #fff;
  }
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }


  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
 </style>
  